<template>
  <div class="picking">
    <div class="main">
      <van-form ref="vanForm"
                :disabled="isClick"
                @failed="onFail">
        <van-field v-model="pickingInfo.usingStatus"
                   name="类型"
                   label="类型">
          <template #input>
            <div :class="{ disabled: isClick }"
                 v-if="pickingInfo.usingStatus == 'RECIPIENT'">
              领用
            </div>
            <div :class="{ disabled: isClick }"
                 v-if="pickingInfo.usingStatus == 'LENDING'">
              借用
            </div>
          </template>
        </van-field>
        <van-field v-model="pickingInfo.lendingNumber"
                   name="出库单号"
                   label="出库单号"> </van-field>
        <van-field v-model="pickingInfo.applicantName"
                   name="申请人"
                   label="申请人"> </van-field>
        <van-field v-model="pickingInfo.creationTime"
                   name="申请时间"
                   label="申请时间"> </van-field>
        <van-field v-model="pickingInfo.organizationName"
                   name="单位"
                   label="单位"> </van-field>
        <van-field v-model="pickingInfo.lendingStartDate"
                   name="领取日期"
                   label="领取日期"> </van-field>
        <van-field v-model="pickingInfo.lendingEndDate"
                   name="归还日期"
                   label="归还日期"> </van-field>
        <van-field v-model="pickingInfo.storagePlace"
                   name="存放位置"
                   label="存放位置"> </van-field>
        <van-field v-model="pickingInfo.pickName"
                   name="提货人"
                   label="提货人"> </van-field>
        <van-field v-model="pickingInfo.pickMobileNumber"
                   name="联系电话"
                   label="联系电话"> </van-field>
        <van-field name="申请理由"
                   label="申请理由"> </van-field>
        <van-field name="资产明细"
                   label="资产明细">
          <template #input>
            <div class="text-button"
                 @click="scanAdd">
              扫码
            </div>
          </template>
        </van-field>

        <van-collapse v-model="collapseList">
          <van-collapse-item v-for="item in borrowList"
                             :class="[item.pickStatus == 'NOT_PICKED' ? 'not-picked-row' : 'picked-row']"
                             :key="item.id"
                             :title="item.name"
                             :name="item.id">
            <div class="content">
              <div class="content-line">
                <span class="name">资产编码:</span>
                <span class="value">{{ item.code }}</span>
              </div>
              <div class="content-line">
                <span class="name">资产图片:</span>
                <van-uploader disabled
                              :deletable="false"
                              :show-upload="false"
                              v-if="item.srcList && item.srcList.length"
                              v-model="item.srcList" />
                <span v-else>暂无数据</span>
              </div>
              <div class="content-line">
                <span class="name">资产分类:</span>
                <span class="value">{{ item.assetCategory }}</span>
              </div>
              <div class="content-line">
                <span class="name">品牌:</span>
                <span class="value">{{ item.brandName }}</span>
              </div>
              <div class="content-line">
                <span class="name">规格型号:</span>
                <span class="value">{{ item.specifications }}</span>
              </div>
              <div class="content-line">
                <span class="name">计量单位:</span>
                <span class="value">{{ item.measuringUnit }}</span>
              </div>
              <div class="content-line">
                <span class="name">存放位置:</span>
                <span class="value">{{ item.storagePlace }}</span>
              </div>
              <div class="content-line">
                <span class="name">所属单位:</span>
                <span class="value">{{ item.manufacturer }}</span>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>

        <div class="action">
          <van-button class="save"
                      round
                      block
                      type="primary"
                      :disabled="pickingInfo.pickStatus == 'SUBMITTED' || isClick"
                      @click="onSubmit('SUBMITTED')"
                      native-type="submit">提 交</van-button>
          <van-button class="save"
                      round
                      block
                      type="info"
                      :disabled="pickingInfo.pickStatus == 'SUBMITTED' || isClick"
                      @click="onSubmit('STAGING')"
                      native-type="submit">暂 存</van-button>
        </div>
      </van-form>

      <!-- 拣货弹框 -->
      <van-dialog v-model="showDialog"
                  id="van-dialog"
                  title="拣货"
                  @confirm="dialogSubimt"
                  class="van-dialog"
                  show-cancel-button>
        <div class="dialog-content">
          <div class="content">
            <div class="content-line">
              <span class="name">资产名称:</span>
              <span class="value">{{ info.name }}</span>
            </div>
            <div class="content-line">
              <span class="name">规格型号:</span>
              <span class="value">{{ info.specifications }}</span>
            </div>
            <div class="content-line">
              <span class="name">资产条码:</span>
              <span class="value">{{ info.barcode }}</span>
            </div>
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import "@/assets/less/views/assets/picking.less";

import { SUCCESS_CODE } from "@/apis/notification";
import Filter from "ldap-filters";
import wxapi from "@/lib/wechat";

import { flatMap, find } from "lodash-es";
let vm;
export default {
  name: "borrowDetail",
  data() {
    return {
      collapseList: [],
      collapse: "",
      showDialog: false,
      listStatus: {
        loading: true,
        finished: false
      },

      pickingInfo: {
        lendingNumber: "",
        lendingStartDate: "",
        lendingEndDate: "",
        organizationName: "",
        pickName: "",
        pickMobileNumber: "",
        applicantName: "",
        storagePlace: "",
        status: "",
        items: [],
        assetIds: ""
      },
      screen: {
        assetCategory: "",
        searchContent: "",
        searchKey: "code",

        usingStatus: "AVAILABLE",
        status: "ENABLE"
      },
      pager: {
        pageNumber: 1,
        pageSize: 5,
        totalRecords: 0
      },
      list: [],
      borrowList: [],
      assetsList: [],
      selectList: [],
      organizationList: [],
      isClick: false,
      searchTypeOptions: [
        { text: "资产编码", value: "code" },
        { text: "资产名称", value: "name" },
        { text: "品牌名称", value: "brandName" }
      ],
      filter: {
        status: {
          id: "",
          label: "资产分类"
        },
        title: "资产分类"
      },
      info: {}
    };
  },
  methods: {
    async init() {
      vm = this;
      this.isClick = false;
      let { id } = this.$route.query;
      if (id) {
        this.getAssetLendingsId(id);
        // this.isClick = true;
      }
    },

    initData() { },
    onFail(arg) {
      arg.errors.forEach(item => {
        this.$notify({ message: item.message, type: "warning" });
      });
    },

    scanAdd() {
      // if (this.isClick) return false;
      if (this.pickingInfo.pickStatus == "SUBMITTED") return false;
      wx.scanQRCode({
        needResult: 1,
        scanType: ["qrCode", "barCode"],
        success: res => {
          var code = res.resultStr.split(",").splice(-1)[0];
          this.getAssetsInfo(code);
        }
      });
      // 111041005-2200799-009527
      // this.getAssetsInfo("111041005-2200799-009527");
    },

    getQuery() {
      let filters = [];
      Object.keys(this.screen).forEach(key => {
        let filter = "equalTo";
        let value = this.screen[key];
        if (!this.screen[key] || key == "searchKey") return false;
        if (key == "searchContent") {
          key = this.screen.searchKey;
          value = this.screen.searchContent;
          filter = "contains";
        }
        if (key == "usingStatus") {
          let query = Filter.OR([Filter.attribute(key)[filter]("SECULAR_RECIPIENT"), Filter.attribute(key)[filter]("AVAILABLE")]);
          filters.push(query);
          return false;
        }
        let query = Filter.attribute(key)[filter](value);
        filters.push(query);
      });
      if (filters.length == 1) {
        return filters[0].toString();
      } else if (filters.length > 1) {
        return Filter.AND(filters).toString();
      }
    },
    onLoad() {
      this.getAssets(true);
    },
    handlePagination(totalPages) {
      if (this.pager.pageNumber < totalPages) {
        // 有更多数据
        this.pager.pageNumber += 1;
      } else {
        this.listStatus.finished = true;
      }
    },

    dialogSubimt(type) {
      let ind = this.pickingInfo.items.findIndex(val => val.assetId == this.info.id);
      let ids = this.$route.query.id;
      if (ind == -1) return this.$notify({ message: "拣货失败", type: "warning" });
      let id = this.pickingInfo.items[ind].id;
      this.putAssetLendingsItemPicking({ id, code: this.info.code, pickStatus: "PICKED" }, ids, type);
    },
    closeDialog() {
      this.showDialog = false;
      this.info = {};
    },
    onSubmit(type) {
      let isFlag = false;
      let { id } = this.$route.query;
      if (type == "SUBMITTED") {
        isFlag = this.pickingInfo.items.every(val => {
          return val.pickStatus == "PICKED";
        });
        if (isFlag) {
          this.isClick = true;
          this.putAssetLendingsPicking(type, id);
        }
        if (!isFlag) this.$utils.warning("存在尚未拣货数据");

        return false;
      }
      this.putAssetLendingsPicking(type, id);
    },

    // 资产借出物品拣货
    async putAssetLendingsItemPicking(params, id, type) {
      let ret = await this.$api.putAssetLendingsItemPicking({ params, placeholder: { id } });
      if (ret.code != SUCCESS_CODE) return;
      let ids = this.$route.query.id;
      this.getAssetLendingsId(ids);
      this.$utils.success("拣货成功");
      this.init();
      this.closeDialog();
      if (type == "next") {
        this.scanAdd();
      }
    },

    // 获取拣货信息
    async getAssetsInfo(code) {
      if (!code) return false;
      let ret = await this.$api.getAssets({ params: { query: "(barcode=" + code + ")" } });
      if (ret.data.length == 0) {
        this.$notify({ message: "暂无数据", type: "warning" });
      }
      this.info = ret.data[0] || {};
      this.showDialog = true;
    },

    //资产拣货
    async putAssetLendingsPicking(status, id) {
      let ret = await this.$api.putAssetLendingsPicking({ params: { status }, placeholder: { id } });
      if (ret.code != SUCCESS_CODE) return;
      if (status == "STAGING") this.$utils.success("暂存成功");
      if (status == "SUBMITTED") this.putAssetLendingStatus();
      setTimeout(async () => {
        await this.$router.go(-1);

        setTimeout(() => {
          window.scrollTo(0, 0);
          this.$parent.$refs[window.currentRoute].init();
        }, 500);
      }, 1500);
    },
    // 修改资产帐-使用单位状态
    async putAssetLendingStatus() {
      let params = {
        ids: [this.$route.query.id],
        status: "SUCCESS"
      };
      let ret = await this.$api.putAssetLendingStatus({ params });
      if (ret.code != SUCCESS_CODE) return;
      let ids = this.pickingInfo.items.map(val => {
        return val.assetId;
      });
      this.putAssetsUsingStatus(ids);
      // this.$utils.success("提交成功");
    },

    // 获取资产帐-使用单位详情
    async getAssetLendingsId(id) {
      let ret = await this.$api.getAssetLendingsId({ placeholder: { id } });
      this.pickingInfo = ret.data;
      this.borrowList = await Promise.all(
        ret.data.items.map(async val => {
          let srcList = [];
          if (val.attachments.length) {
            srcList = await this.getAppendixes(val.attachments);
          }
          return {
            srcList,
            ...val
          };
        })
      );
      if (this.borrowList.length > 0) {
        this.pickingInfo.assetIds = this.borrowList.length;
      } else {
        this.pickingInfo.assetIds = "";
      }
    },
    // 获取解密图片
    async getAppendixes(par) {
      let urls = par
        .map(val => {
          return val.attachmentUrl;
        })
        .join(",");
      let ret = await this.$api.getAppendixFilePath({ params: { urls } });
      if (ret.data.length) {
        let srcList = ret.data.map(val => {
          return {
            url: val
          };
        });
        return srcList;
      }
      return [];
    },

    //获取资产
    async getAssets(sign = false) {
      let params = { pageNumber: this.pager.pageNumber - 1, pageSize: this.pager.pageSize };
      params.query = this.getQuery();
      let ret = await this.$api.getAssets({ params });
      this.ids = this.borrowList.map(val => {
        return val.id;
      });
      // this.list = ret.data;
      if (sign) {
        this.list = this.list.concat(ret.data);
      } else {
        this.list = ret.data;
      }
      this.listStatus.loading = false;
      this.pager.totalRecords = ret.totalRecords;
      this.selectList = this.borrowList.map(val => {
        return val.id;
      });
      this.handlePagination(ret.totalPages);
      // this.list.forEach(val => {
      //   this.$refs.account.toggleRowSelection(val, this.ids.includes(val.id));
      // });
    }
  },
  created() {
    this.init();
  },
  mounted() {
    wxapi.ready(["scanQRCode"]).then(res => {
      console.log(res);
    });
  },
  filters: {
    getStatus(val) {
      if (val == "ENABLE") return "正常";
      if (val == "REPAIR") return "报修";
      if (val == "DAMAGED") return "报损";
      if (val == "DISCARDED") return "报废";
    },
    getUsingStatus(val) {
      if (val == "AVAILABLE") return "可用";
      if (val == "SECULAR_RECIPIENT") return "长期领用";
      if (val == "TEMPORARY_LENDING") return "临时借出";
      if (val == "SECULAR_RECIPIENT_UNDER_REVIEW") return "长期领用审核中";
      if (val == "TEMPORARY_LENDING_UNDER_REVIEW") return "临时借出审核中";
    }
  }
};
</script>
